import React from 'react'
import { cn } from '../../../utils/classnames'

const Wrapper = ({
    children,
    variant = 'default',
}: {
    children: React.ReactNode
    variant?: 'default' | 'orange'
}) => {
    return (
        <div
            className={cn('flex flex-col rounded-[32px] p-8 gap-4 lg:p-12 !border border-solid', {
                'bg-white border-grey-20': variant === 'default',
                '!bg-primary text-white !border-primary': variant === 'orange',
            })}>
            {children}
        </div>
    )
}

const Title = ({
    children,
    variant = 'default',
    icon: IconComponent,
}: {
    children: React.ReactNode
    variant?: 'default' | 'orange'
    icon: React.ReactNode
}) => {
    return (
        <div className='grid grid-cols-[1fr_112px] gap-4 mb-2'>
            <h3
                className={cn('font-normal text-hd1 lg:text-hd2 2xl:text-hd3 w-full font-sans', {
                    'text-black': variant === 'default',
                    'text-white': variant === 'orange',
                })}>
                {children}
            </h3>

            <div
                className={cn(
                    'w-18 aspect-square flex items-center justify-center rounded-xl lg:w-[112px] lg:rounded-2xl',
                    {
                        'bg-[rgba(0,0,0,0.05)]': variant === 'default',
                        'bg-[rgba(255,255,255,0.2)]': variant === 'orange',
                    },
                )}>
                {IconComponent}
            </div>
        </div>
    )
}

const Body = ({
    children,
    variant = 'default',
}: {
    children: React.ReactNode
    variant?: 'default' | 'orange'
}) => {
    return (
        <div
            className={cn('w-full flex-1 mb-4  lg:mb-10 ', {
                'text-black text-bd5': variant === 'default',
                'text-white text-bd5': variant === 'orange',
            })}>
            {children}
        </div>
    )
}

const Button = ({
    text,
    href,
    variant = 'default',
}: {
    text: string
    href: string
    variant?: 'default' | 'orange'
}) => {
    return (
        <a
            href={href}
            className={cn(
                'w-full md:w-auto inline-flex items-center justify-center px-6 py-3 rounded-[40px] transition-colors duration-200 lg:px-12 lg:py-6 font-sans text-bd3 lg:text-bd5 xl:self-center',
                {
                    '!bg-primary hover:!bg-primary-dark text-white': variant === 'default',
                    '!bg-white !text-primary': variant === 'orange',
                },
            )}>
            {text}
        </a>
    )
}

export const PricingCard = {
    Wrapper,
    Title,
    Body,
    Button,
}
